.nextImageWrapper {
  
  .nextImageLayoutFill {
    position: relative !important; /* override next/image in-line styles */
    height: unset !important; /* override next/image in-line styles */
  }

  & span:first-child {
    position: relative !important;
    flex: 1;

    .nextImage {
      width: 100%;
      height: 100%;
      background-repeat: no-repeat !important; /* important added to ensure background does not repeat when next adds inline background image */
    }
  }
}
