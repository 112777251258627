@import "../../typography.css";
@import "../../mixins.css";
@import "../../design_tokens.css";

.accountButtonText {
  @mixin linkText;
  @mixin hide_on_smartphone_and_tablet;
  @mixin caret-down-menu;
  text-transform: capitalize;
}

.accountIconWrapper {
  display: flex;
  position: relative;
  @mixin hide_on_desktop;
}

.authWrapper {
  display: flex;
  align-items: center;
  height: $desktopMinifiedHeaderHeight;
  line-height: $desktopMinifiedHeaderHeight;
  cursor: pointer;
  @media(--smartphone-and-tablet) {
    height: $mobileHeaderHeight;
    line-height: $mobileHeaderHeight;
  }
  @media (--smartphone) {
    height: unset;
    line-height: unset;
    & > a {
      padding: 11px;
    }
  }
}
