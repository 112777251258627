@import "../../mixins.css";
@import "../../typography.css";


.container {
  container-type: inline-size;
}

.selectors {
  display: grid;
  grid-template-columns: 1fr;
  gap: $spacingMd;
  @container (min-width: 450px) {
    gap: $spacingSm;
    grid-template-columns: 1fr 1fr;
  }
  @container (min-width: 550px) {
    gap: $spacingXl;
  }
}

.label{
  text-transform: uppercase;
  display: inline-block;
  margin-bottom: $spacingXs;
  @mixin eyebrow
}

.buttonContainer{
  display: flex;
  margin-top: $spacingLg;
  justify-content: center;
}

.iframe {
  border: 0px none;
  width: 100%;
  height: 80vh;
}

.error{
  display: inline-block;
  margin-top: $spacingXs;
  margin-bottom: 0;
  @mixin text_error;
  @mixin metaDescriptionText;
}