@import "./swatch_common.css";
@import "../mixins.css";

.component {
  display: flex;
  overflow-x: scroll;
  scroll-behavior: smooth;
  padding-bottom: $spacingXs;

  &.small {
    .swatch {
      width: $smallSize;
      height: $smallSize;
    }
  }
}

.swatch {
  margin: 4px 8px;
  width: $defaultSize;
  height: $defaultSize;
  cursor: pointer;
}
