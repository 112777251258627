@import "../../media.css";
@import "../../typography.css";
@import "../../mixins.css";

.component {
  padding-top: 12%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.ctaContainer {
  padding: 15px 0;
}

.cta {
  display: inline-block;
  padding: 15px 40px;
  @mixin metaTitleText;
  @mixin background_alternate;
  border: 1px solid transparent;
  @mixin text_white;
  text-transform: uppercase;
  border-radius: $spacingNudge;
  &:focus, &:hover {
    @mixin background_brand;
    cursor: pointer;
  }
}

.contentBlock {
  margin: 0 $spacingHuge;
  max-width: 1440px;
  text-align: initial;

  @media (--smartphone-and-tablet) {
    margin: 0 $spacingSm;
  }
}

.copy {
  width: 100%;
  margin-bottom: $spacingMd;

  @media (--smartphone) {
    padding: 0 20px;
  }
}

.title {
  text-transform: none;
  cursor: default;
  margin-bottom: 15px;
  letter-spacing: 0.06em;
  line-height: 1.5;
  white-space: normal;

  @media (--tablet-and-desktop) {
    letter-spacing: 0.01em;
    line-height: 1;
  }
}

.description {
  @mixin subheaderText;
  margin-bottom: 15px;
  white-space: normal;

  @media (--tablet-and-desktop) {
    line-height: 1.5;
  }
}

.secondary {
  .copy {
    .title {
      @mixin headerText;
      text-transform: none;
    }
  }
}

