.component {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
  justify-content: space-between;
}

.noWrap {
  flex-wrap: nowrap;
  overflow-x: scroll;
}
