@import "../../media.css";
@import "../../typography.css";
@import "../../mixins.css";

.articlesList {
  width: 100%;
  margin: $spacingMd auto;
  max-width: 1400px;

  .articlesTitle {
    @mixin displayText;
    font-weight: 400;
    margin-left: $spacingLg;
  }

  .articleTileContainer {
    display: flex;
    overflow-x: scroll;
  }

  .articleTile {
    padding: $spacingMd;
    margin: $spacingXs;
    color: $black;
    width: 300px;
  }

  .articleImage {
    margin: 0 auto;
    width: calc(300px - $spacingMd);
    height: 200px;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .articleCategory {
    @mixin captionTitleText;
    @mixin typeTertiary;
  }

  .articleTitle {
    @mixin captionTitleText;
    font-size: 20px;
    font-weight: 500;
    line-height: 1;
  }

  .articleDescription {
    @mixin typeTertiary;
    font-size: 14px;
    font-weight: 100;
  }
}
