.starIconContainer {
  position: relative;

  &:not(:last-child) {
    margin-right: 1rem;
  }
}

.starIcon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}