@import "../../media.css";
@import "../../typography.css";
@import "../../mixins.css";

.contentSection {
  -webkit-font-smoothing: antialiased;
  width: 100%;

  .title{
    @mixin displayText;
    font-weight: 700;
    margin-bottom: $spacingMd;
  }

  .richText{
    p {
      @mixin bodyText;
      @mixin text_primary;

      button {
        a {
          color: inherit;
        }
      }

      a {
        @mixin typeTertiary;
        @mixin text_link;
      }
    }

    ul {
      list-style-type: disc;
      padding-inline-start: $spacingLg;
      list-style-position: outside;
      p {
        display: inline;
        margin: 0 0 1em 0;
      }
      ul {
        list-style-type: circle;
        ul {
          list-style-type: square;
        }
      }
    }

    ol {
      list-style-type: decimal;
      list-style-position: outside;
      p {
        display: inline;
      }
      ol {
        list-style-type: upper-alpha;
        ol {
          list-style-type: lower-roman;
        }
      }
    }
  }
}

.default {
  .richText{
    h6 {
      @mixin typeVanity;
      @mixin text_primary;
      font-size: 0.9rem;
      letter-spacing: 1px;
      text-transform: uppercase;
      margin: 1rem auto;
      line-height: 1.5;
    }

    h5 {
      @mixin typePrimary;
      font-size: 0.85rem;
      @mixin text_primary;
      margin: 1rem auto;
      line-height: 1.5;
    }

    h4 {
      @mixin typePrimary;
      font-size: 1rem;
      @mixin text_primary;
      margin: 1rem auto;
      line-height: 1.5;
    }

    h3 {
      @mixin typeTertiary;
      font-size: 1.5rem;
      @mixin text_primary;
      margin: 1rem auto;
      line-height: 1.2;
    }

    h2 {
      @mixin typeTertiary;
      font-size: 2.5vw;
      @mixin text_primary;
      margin: 1rem auto;
      line-height: 1.2;
      @media (--tablet) {
        font-size: 3.5vw;
      }
      @media (--smartphone) {
        font-size: 5.2vw;
      }
    }

    h1 {
      @mixin typeTertiary;
      font-size: 4vw;
      @mixin text_primary;
      margin: 1rem auto;
      line-height: 1;
      @media (--tablet) {
        font-size: 5.5vw;
      }
      @media (--smartphone) {
        font-size: 8vw;
      }
    }
  }
}

.smaller {
  .richText{
    h6 {
      @mixin typeVanity;
      @mixin text_primary;
      font-size: 0.5rem;
      letter-spacing: 1px;
      text-transform: uppercase;
      margin: 1rem auto;
      line-height: 1.5;
    } 

    h5 {
      @mixin typeVanity;
      @mixin text_primary;
      font-size: 0.9rem;
      letter-spacing: 1px;
      text-transform: uppercase;
      margin: 1rem auto;
      line-height: 1.5;
    }

    h4 {
      @mixin typePrimary;
      font-size: 0.85rem;
      @mixin text_primary;
      margin: 1rem auto;
      line-height: 1.5;
    }

    h3 {
      @mixin typePrimary;
      font-size: 1rem;
      @mixin text_primary;
      margin: 1rem auto;
      line-height: 1.5;
    }

    h2 {
      @mixin typeTertiary;
      font-size: 1.5rem;
      @mixin text_primary;
      margin: 1rem auto;
      line-height: 1.2;
    }

    h1 {
      @mixin typeTertiary;
      font-size: 2.5vw;
      @mixin text_primary;
      margin: 1rem auto;
      line-height: 1.2;
      @media (--tablet) {
        font-size: 3.5vw;
      }
      @media (--smartphone) {
        font-size: 5.2vw;
      }
    }
  }
}
