@import "../../mixins.css";

.desktop {
  width: 14%;
  height: 100%;
  position: sticky;
  padding-bottom: 80px;
  @mixin hide_on_smartphone_and_tablet;
}

.smartPhoneAndTablet {
  flex: 1;
  @mixin hide_on_desktop;
}
