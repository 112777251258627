@import "../../media.css";
@import "../../typography.css";
@import "../../mixins.css";
@import "../../design_tokens.css";

.component {
  position: relative;

  hr {
    border-top: 1px solid;
  }
}

.isLoading {
  min-height: 400px;
}

.buttonContainer {
  width: 250px;
  margin: $spacingSm auto;
}

.share {
  @mixin background_secondary;
  padding: $spacingMd;
  margin: $spacingMd 0;
  text-align: center;

  @media (--smartphone) {
    margin: $spacingSm 0;
    padding: $spacingSm;
  }
}

.shareHeader {
  @mixin headerText;
  @mixin text_primary;
  margin-top: 0;

  @media (--smartphone) {
    @mixin subheaderText;
  }
}

.tagline {
  @mixin metaDescriptionText;
  @mixin text_secondary;
}

.sharedLink {
  @mixin metaDescriptionText;
  @mixin text_primary;
  text-decoration: underline;
}

.publicComponent {
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 5%;
}

.publicUser {
  text-align: center;
  @mixin headerText;
}

.inline {
  width: 100%;
  max-width: $(productWrapperMaxWidth)px;
  padding: 0 $(productWrapperHorizonalPadding)%;

  @media (--smartphone-and-tablet) {
    padding: 0;
    h2 {
      padding: 0 $spacingMd;
    }
  }

  h2 {
    @mixin subheaderText;
    @media (--smartphone-and-tablet) {
      @mixin metaTitleText;
    }
  }
}