@import "../../media.css";
@import "../../typography.css";
@import "../../mixins.css";

.component {
  @mixin captionDescriptionText;
  font-size: 12px;
  padding: $spacingSm 0;
  width: 100%;
  text-align: center;

  .terms,
  .privacy {
    @mixin captionDescriptionText;
    @mixin text_secondary_alternate;
    font-size: 12px;
    padding: 0;
    cursor: pointer;
    appearance: none;
    text-decoration: underline;
    background-color: transparent;
    border: none;
    line-height: 1.5;
  }

  &.light {
    padding: $spacingSm 0;
    .terms,
    .privacy,
    .a {
      @mixin text_brand;
    }
  }
}
