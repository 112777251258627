@import "../../media.css";
@import "../../typography.css";
@import "../../mixins.css";
@import "../../colors.css";

.component {
  border-top: 1px solid transparent;
  padding: $spacingXl $spacingLg;
  overflow: hidden;
  @mixin background_primary;

  width: 100%;

  @media (--desktop) {
    width: 100%;
    left: 0;
    min-height: 350px;
    padding: $spacingHuge;
  }

  @media (--smartphone-and-tablet) {
    padding: 0 $spacingXs $spacingMd;
  }
}

.footerContents {
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  max-width: 1440px;
  margin: 0 auto;
  justify-content: space-between;
  @media (--smartphone-and-tablet) {
      flex-direction: column;
  }
}

.social {
  width: 100%;
  padding: $spacingLg $spacingSm;
  @media (--desktop) {
    width: 45%;
    padding: $spacingXl;
  }
}

.links {
  width: 100%;
  padding: $spacingSm;
  @media (--desktop) {
    width: 40%;
    padding: $spacingXl;
  }
}

.socialIcons {
  padding-top: $spacingSm;

  svg {
    width: 1em;
    height: 1em;
  }
}

.socialIcon {
  display: inline-block;
  margin-right: $spacingXs;
  font-size: 20px;
  cursor: pointer;
  @mixin icon_color_secondary;

  &:hover {
    @mixin icon_color_primary;
  }
}

.links {
  display: flex;
  flex-direction: row;
}

.linkRow {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  text-wrap: balance;
  width: min-content;
}

.linkRow > a {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.linkRow > a > svg {
  margin-right: $spacingXs;
  width: $spacingLg;
}
.email {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.copyright {
  @mixin metaTitleText;
  @mixin text_primary;
  max-width: 1440px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 $spacingXl;
   @media (--smartphone-and-tablet) {
    padding: 0;
    flex-direction: column-reverse;
  }
}

.cookiePreference {
  border: none;
  padding: 0;
  background-color: transparent;
  text-align: left;
  font-family: "Spezia Regular";
  font-style: normal;
  font-weight: 400;
  color: $grey7;
  margin-top: $spacingXs;
  margin-bottom: $spacingXs;
}