@import "../typography.css";
@import "../colors.css";
@import "../mixins.css";

.accordion__item + .accordion__item {
  @mixin border_top_primary;
}

.accordion__item {
  &.spacingXs {
    padding: $spacingXs 0;
  }
  &.spacingSm {
    padding: $spacingSm 0;
  }
  &.spacingMd {
    padding: $spacingMd 0;
  }
  &.spacingLg {
    padding: $spacingLg 0;
  }
  &.spacingXl {
    padding: $spacingXl 0;
  }
  &.spacingHuge {
    padding: $spacingHuge 0;
  }
}

.accordion__button {
  @mixin background_primary;
  @mixin metaTitleText;
  @mixin text_primary;
  cursor: pointer;
  width: 100%;
  text-align: left;
  border: none;
  position: relative;

  &.fontWeight900 {
    font-weight: 900 !important;
  }
}

.wordBreak {
  word-break: break-word;
}

.expandIcon {
  display: inline-block;
  float: right;
  margin-right: 12px;
  font-size: 18px;
  position: absolute;
  right: 0;
  top: calc(50% - 9px); /* 9px is 1/2 height of icon */
}

.accordion__panel {
  margin: Nudge$spacing 0 $spacingSm;
  overflow: hidden;
}

.hidden {
  display: block;
  max-height: 0;
  margin: 0;
  @mixin transitionTwoWithEffect max-height, margin, cubic-bezier(0, 1, 0, 1);
}

.defaultLayout {
  @mixin metaDescriptionText;

  .accordion__button {
    @mixin background_primary;
    @mixin metaTitleText;
    @mixin text_primary;
    cursor: pointer;
    width: 100%;
    text-align: left;
    border: none;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .expandIcon {
    display: inline-block;
    float: right;
    margin-left: 0px;
    width: 10px;
  }

  .accordion__panel:not(.hidden) {
    max-height: 100%;
    @mixin transitionTwoWithEffect max-height, margin, ease-in-out;
  }
}

.largeLayout {
  @mixin metaDescriptionText;

  .accordion__button {
    @mixin background_primary;
    @mixin bodyTitleText;
    @mixin text_primary;
    cursor: pointer;
    width: 100%;
    text-align: left;
    border: none;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .expandIcon {
    display: inline-block;
    float: right;
    margin-right: 0px;
    width: 10px;
  }

  .accordion__panel:not(.hidden) {
    max-height: 1500px;
    @mixin transitionTwoWithEffect max-height, margin, ease-in-out;
  }
}

.plpSideNav {
  @mixin metaDescriptionText;
  @mixin hide_on_smartphone_and_tablet;

  a {
    color: $black;
    text-decoration: none;
  }

  .accordion__button {
    @mixin background_primary;
    @mixin metaDescriptionText;
    padding: $spacingNudge 0;
    cursor: pointer;
    width: 100%;
    text-align: left;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    div {
      padding-right: $spacingSm;
    }

    &:hover {
      color: $black;
      font-weight: 600;
    }
  }

  .expandIcon {
    display: inline-block;
    float: right;
    margin: 0 $spacingNudge;
    width: 10px;
  }

  .accordion__panel:not(.hidden) {
    max-height: 1500px;
    @mixin transitionTwoWithEffect max-height, margin, ease-in-out;
  }
}
