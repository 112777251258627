@import "../mixins.css";

.contentfulComponent {
  width: 100%;

  .productTile {
    width: 100%;
  }
}

.contentfulComponentHeight {
  min-height: 400px;
}
