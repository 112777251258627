@import "../../typography.css";
@import "../../mixins.css";

.menuLink {
  padding: 0 $spacingNudge $spacingSm;
  margin: 0 $spacingXs;
  text-transform: uppercase;
  font-size: 13px !important;

  @mixin border_bottom_transparent;
  z-index: 1;

  @media (--smartphone-and-tablet) {
    display: none;
  }

  &.active {
    @mixin border_bottom_dark;
  }
}
