@import "../../media.css";
@import "../../typography.css";
@import "../../mixins.css";

.contentSection {
  -webkit-font-smoothing: antialiased;
  margin:  $spacingMd auto;
  max-width: 1400px;

  @media (--smartphone-and-tablet) {
    margin: $spacingXl 0;
  }

  .category {
    @mixin captionTitleText;
    @mixin typeTertiary;
    display: flex;
    width: 100px;
    text-transform: uppercase;
    margin: 0 auto;
  }

  .title {
    @mixin displayText;
    width: 80%;
    justify-content: center;
    text-align: center;
    margin: 1rem auto 0;
    font-weight: 300;
    letter-spacing: -1.26px;  
    margin-bottom: $spacingMd;
  }

  .description {
    font-size: 14px;
    text-align: center;
    justify-content: center;
    margin: 0 auto;
    font-weight: 100;
    padding: 0 $spacingMd;

    @media (--tablet-and-desktop) {
      width: 473px;
      padding: 0;
    }
  }

  .mins {
    @mixin captionTitleText;
    @mixin typeTertiary;
    display: flex;
    justify-content: center;
    margin: 1rem auto 0;
  }

  .date {
    @mixin labelText;
    font-size: 10px;
    display: flex;
    justify-content: center;
    margin: 0 0 1rem;
  }

  .articleImage {
    max-width: 90%;
    justify-content: center;
    margin: 0 auto;

    @media (--smartphone) {
      max-width: 100%;
    }
  }

  .separator {
    width: 25%;
    margin: $spacingXl auto;
  }

  .richText {
    @media (--smartphone-and-tablet) {
      padding: 0 $spacingMd;
    }

    p {
      @mixin text_primary;
      line-height: 1.5;
      button {
        a {
          color: inherit;
        }
      }

      a {
        @mixin typeTertiary;
        @mixin text_link;
      }
    }

    ul {
      list-style-type: disc;
      padding-inline-start: $spacingLg;
      list-style-position: outside;
      p {
        display: inline;
        margin: 0 0 1em 0;
      }
      ul {
        list-style-type: circle;
        ul {
          list-style-type: square;
        }
      }
    }

    ol {
      list-style-type: decimal;
      list-style-position: outside;
      p {
        display: inline;
      }
      ol {
        list-style-type: upper-alpha;
        ol {
          list-style-type: lower-roman;
        }
      }
    }

    h6 {
      @mixin typeVanity;
      @mixin text_primary;
      font-size: 0.9rem;
      letter-spacing: 1px;
      text-transform: uppercase;
      margin: 1rem auto;
      line-height: 1.5;
    }

    h5 {
      @mixin typePrimary;
      font-size: 0.85rem;
      @mixin text_primary;
      margin: 1rem auto;
      line-height: 1.5;
    }

    h4 {
      @mixin typePrimary;
      font-size: 1rem;
      @mixin text_primary;
      margin: 1rem auto;
      line-height: 1.5;
    }

    h3 {
      @mixin typeTertiary;
      font-size: 1.5rem;
      @mixin text_primary;
      margin: 1rem auto;
      line-height: 1.2;
    }

    h2 {
      font-size: 2rem;
      @mixin text_primary;
      margin: 1rem auto;
      line-height: 1.2;
      font-weight: 300;
      
      @media (--tablet) {
        font-size: 2rem;
      }
      @media (--smartphone) {
        font-size: 1.5rem;
      }
    }

    h1 {
      @mixin typeTertiary;
      font-size: 3rem;
      @mixin text_primary;
      margin: 1rem auto;
      line-height: 1;
      font-weight: 300;

      @media (--tablet) {
        font-size: 2rem;
      }
      @media (--smartphone) {
        font-size: 1.5rem;
      }
    }
  }

  div[class*="horizontal_scroll_area_scrollItem"] {
    width: 60% !important;
  }

  @media (--tablet-and-desktop) {
    div[class*="contentful-product-tile"]{
      width: 33%;
    }
  
    div[class*="horizontal_scroll_area_scrollItem"] {
      width: 30% !important;
      div[class*="contentful-product-tile"]{
        width: 100%;
      }
    }
  }
}
