@import "../typography.css";
@import "../mixins.css";
@import "../colors.css";

.component {
  @mixin flex_center;
  @mixin typePrimary;
  font-style: normal;
  background: $transparentGrey1;
  border-radius: 50%;
  @mixin text_primary;
  cursor: pointer;
  font-size: 20px;
  height: 50px;
  position: absolute;
  right: 20px;
  top: calc(20px - 4px);
  width: 50px;
  z-index: 150;
  border: none;

  @media (--tablet-and-desktop) {
    right: 40px;
    top: calc(40px - 4px);
  }
}

.noBackground {
  &.component {
    @mixin background_primary;
  }
}
