@import "../typography.css";
@import "../colors.css";
@import "../mixins.css";

.component {
  a {
    color: inherit;
    @mixin navigationText;
  }

  &.dark {
    @mixin border_bottom_primary;
  }

  &.dark.active {
    border-bottom: 1px solid $grey5;
  }

  &.dark a {
    padding: 10px 20px;
    display: inline-block;
  }

  button {
    border: none;
    background: inherit;
    color: inherit;
    appearance: none;
    font: inherit;
  }

  &.sliding {
    @mixin metaDescriptionText;
    appearance: none;
    border: none;
    display: inline-block;
    outline: none;
    padding: 10px 20px;
    cursor: pointer;
    button {
      text-transform: capitalize;
    }
    &.dark {
      padding: 0;
    }

    &.accent:hover {
      color: $accent2;
    }

    &.accent:after {
      content: "";
      display: block;
      height: 4px;
      margin-top: 10px;
      background-color: transparent;
    }

    &.active.accent {
      color: $accent2;
      &:after{
        background-color: $accent2;
      }
    }
  }

  &.link {
    appearance: none;
    border: none;
    display: inline-block;
    outline: none;
    padding: 10px 20px;
    cursor: pointer;
    @mixin text_tertiary;

    button {
      text-transform: capitalize;
    }

    &.dark {
      padding: 0;
    }

    &.accent:hover {
      color: $accent2;
    }

    &.active.accent {
      @mixin border_bottom_secondary;
    }
  }

  &.tabbed {
    width: 200px;
    margin: 0 8px 0 0;

    padding: 11px 0;
    appearance: none;
    @mixin background_secondary;
    @mixin text_tertiary;
    border: none;
    outline: none;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    text-align: center;
    z-index: 2; /*TODO: Change this based on modal context.*/
    position: relative;

    @mixin metaTitleText;
    font-size: 15px;

    &:last-of-type {
      @media(--smartphone) {
        margin-right: 0;
      }
    }

    &:hover {
      @mixin text_primary;
      cursor: pointer;
    }

    &.active {
      z-index: 1;
      background-color: $grey2;
      @mixin text_primary;
      font-weight: bold;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
    }
  }

  &.swatch {
    flex: 1 100%;
    padding: 6px 0;
    appearance: none;
    @mixin background_primary;
    @mixin border_primary;
    outline: none;
    text-align: center;
    z-index: 2;
    position: relative;

    &:hover {
      cursor: pointer;
    }

    &.active {
      z-index: 1;
      @mixin background_tertiary;
      &:after {
        content: " ";
        display: block;
        position: absolute;
        box-sizing: border-box;
        height: 15px;
        width: 15px;
        @mixin background_tertiary;
        transform-origin: center;
        transform: rotate(45deg);
        bottom: -8px;
        left: calc(50% - 8px);
      }
    }
  }
}
