@import "../mixins.css";


.watermarkWrapper {
  position: absolute;
  width: 30px; 
  max-width: 30px; 
  height: 30px;
  max-height: 30px;
  opacity: 0.25;

  &.bottom-left {
    bottom: $spacingSm;
    left: $spacingSm;
  }

  &.line-item-icon {
    width: 18px;
    height: 18px;
    bottom: $spacingXs;
    left: $spacingXs;
  }

  svg {
    position: relative;
    top: 0;
    max-width: 100%;
    max-height: 100%;
  }
}
