@import "../mixins.css";
@import "../typography.css";
@import "../colors.css";

.button {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 42px;
  height: 42px;
  border-radius: 50%;
  border-width: 0;
  padding: 0;
  @mixin icon_color_secondary;
  @mixin background_transparent_grey;

  &:hover {
    @mixin background_primary;
  }

  .wishlistLink {
    display: none;
  }
}

.link {
  @mixin button_medium;
  color: $grey6;
  width: 100%;
  border: transparent;
  background-color: transparent;
  text-decoration: underline;

  > svg {
    margin-right: $spacingXs;
    font-size: $iconSizeSm;
    margin-bottom: -2px;
  }

  &:hover {
    > svg {
      font-size: $iconSizeMd;
    }
  }
}

.carousel {
  z-index: 1;
  right: 50px;
}
