@import "../typography.css";
@import "../mixins.css";
@import "../colors.css";

.component {
  position: relative;
  text-align: center;

  button {
    appearance: none;
    border: none;
    padding: 0;
  }
}

.image {
  width: auto;
  padding-bottom: $spacingXs;
}

.text {
  @mixin subheaderText;
  position: absolute;
  text-transform: capitalize;
  width: 100%;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  text-shadow: 2px 2px 6px #00000061;
}

.light {
  font-weight: bold;
  @mixin text_white;
  text-shadow: 0 0 10px $grey6;
}

.large {
  @mixin text_white;
  font-size: 24px;
}

.dark {
  @mixin text_primary;
}
