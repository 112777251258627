.nextImageWrapper {
  
    .nextImageLayoutFill {
      position: relative !important; /* override next/image in-line styles */
      height: unset !important; /* override next/image in-line styles */
    }
  
    & span:first-child {
      position: relative !important;
      flex: 1;
  
      .nextImage {
        width: 100%;
        height: 100%;
      }
    }

    /* only for the on-hover image */
    &.hoverImageWrapperStyles {    
      & span:nth-child(2) {
        position: absolute !important; /* for overlapping image, needs absolute position */
        top: 0;
        width: 100% !important;  /* for overlapping image, needs to fill container */
        height: 100% !important; /* for overlapping image, needs to fill container */
      }
    }

    .showImage {
      transition: opacity ease 1s;
      opacity: 1 !important;
    }
      
    .hideImage {
      opacity: 0;
      transition: opacity ease 1s;
    }
}
