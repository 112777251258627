.productTile {
  /* Override base product tile width */
  width: 100%!important;
  text-transform: capitalize;
}

.paddingNone {
  [class*="product_tile_productDescription"], [class*="product_tile_subtitle"] {
    padding-left: 0;
    padding-right: 0;
  }
}
